define(function() {
  var a = {
    init: function() {
      var b = this;
      a.events(b), a.evaluateDropdownWidth(b)
    },
    currentTarget: function() {
      if (!a) var a = window.event;
      return a.currentTarget
    },
    events: function(b) {
      var c;
      for (c = 0; c < b.$el.children.length; c++) b.$el.children[c].classList.contains(b.options.navClass) && (b.help.addEvent(b.$el.children[c], 'mouseover', function() {
          var c = a.currentTarget();
          b.help.addClass(c, 'active'), a.showAvailableDropdown(b, c), a.positionDropdown(b)
        }), b.help.addEvent(b.$el.children[c], 'mouseout', function() {
          var c = a.currentTarget();
          b.help.removeClass(c, 'active'), a.hideAvailableDropdown(b, c)
        }));
      var d = b.$el.getElementsByClassName(b.options.navDropdownClass);
      for (c = 0; c < d.length; c++) b.help.addEvent(d[c], 'mouseover', function() {
          var c = a.currentTarget();
          c.previousElementSibling && b.help.addClass(c.previousElementSibling, 'active'), a.setVisibility(c, true)
        }), b.help.addEvent(d[c], 'mouseout', function() {
          var c = a.currentTarget();
          c.previousElementSibling && b.help.removeClass(c.previousElementSibling, 'active'), a.setVisibility(c, false)
        })
    },
    showAvailableDropdown: function(b, c) {
      if (a.isDropdown(b, c)) {
        var d = c.nextElementSibling;
        a.setVisibility(d, true)
      }
    },
    hideAvailableDropdown: function(b, c) {
      if (a.isDropdown(b, c)) {
        var d = c.nextElementSibling;
        a.setVisibility(d, false)
      }
    },
    evaluateDropdownWidth: function(b) {
      for (var c = b.$el.getElementsByClassName(b.options.navDropdownClass), d = [], e = 0; e < c.length; e++) {
        c[e].predictedWidth = 0;
        for (var f = 0; f < c[e].children.length; f++) c[e].predictedWidth = c[e].predictedWidth + c[e].children[f].offsetWidth;
        a.setWidth(c[e], c[e].predictedWidth)
      }
    },
    positionDropdown: function(b) {
      for (var c = b.$el.getElementsByClassName(b.options.navClass), d = 0; d < c.length; d++) {
        var e = a.getPosition(c[d]);
        c[d].nextElementSibling && (c[d].nextElementSibling.style.left = e.x + 'px')
      }
    },
    setWidth: function(a, b) {
      a.style.width = b + 'px'
    },
    getPosition: function(a) {
      var b = 0,
        c = 0;
      while (a) b += a.offsetLeft - a.scrollLeft + a.clientLeft, c += a.offsetTop - a.scrollTop + a.clientTop, a = a.offsetParent;
      return {
        x: b,
        y: c
      }
    },
    isDropdown: function(a, b) {
      if (b.nextElementSibling.classList.contains(a.options.navDropdownClass)) return true;
      return false
    },
    setVisibility: function(a, b) {
      b ? (a.style.display = 'block', a.style.visibility = 'visible') : (a.style.display = 'none', a.style.visibility = 'hidden')
    }
  };
  return {
    initialize: a.init
  }
});